<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <!-- <v-row class="blue-grey darken-4"><Breadcrumbs /></v-row> -->

                <v-row><v-col cols="12"></v-col></v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                    BROKER FILTER
                                </div>
                            </v-card-title>
                            <v-row class="pa-4">
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="brokerMap.length > 1" class="d-flex">
                                    <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap"
                                        item-text="broker" item-value="broker_id" auto-select-first chips clearable deletable-chips
                                        small-chips></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- risk alert pnl 组件 -->
                <RiskAlertPnl v-if="loadRiskAlertPnl" :brokerId="selectedBrokerId" :broker="selectedBroker" />

                <v-row><v-col cols="12"><v-divider/></v-col></v-row>

                <!-- risk notify trade 组件 -->
                <RiskNotifyTrades v-if="loadRiskNotifyTrades" :brokerId="selectedBrokerId" :broker="selectedBroker" />

            </v-container>
        </v-card>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { mapState } from "vuex";
import Breadcrumbs from "@components/Breadcrumbs";
import RiskAlertPnl from "./components/ComponentsRiskAlertPnl";
import RiskNotifyTrades from "./components/ComponentsRiskNotifyTrades";

export default {
    components: { Breadcrumbs, RiskAlertPnl, RiskNotifyTrades },
    mixins: [permission, helper],
    data () {
        return {
            selectedBrokerId: -1,
            selectedBroker: "",
            loadRiskAlertPnl: false,
            loadRiskNotifyTrades: false
        };
    },
    computed: {
        ...mapState(["brokerMap", "snackmsg", "snackbar", "snackcolor"])
    },
    mounted () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;

        this.loadRiskAlertPnl = true;
        this.loadRiskNotifyTrades = true;
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.selectedBroker = this.getBrokerNameById(nv);

            this.loadRiskAlertPnl = false;
            this.loadRiskNotifyTrades = false;

            this.$nextTick(() => {
                this.loadRiskAlertPnl = true;
                this.loadRiskNotifyTrades = true;
            });
        }
    },
    methods: {
    }
};
</script>
