<template>
	<div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            PNL THRESHOLD NOTIFICATION
                        </div>
                    </v-card-title>

                    <v-row class="pt-4 pl-4 pr-4">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <!-- <h3>PNL Threshold Notification</h3> -->
                            <v-alert text class="mt-2" color="info" icon="mdi-cloud-alert" border="left">
                                <p class="mb-0">The following configuration will change the book PNL show in telegram.</p>
                                <p class="mb-0"><strong>1. Interval</strong>: How often the pnl will be sent via telegram
                                </p>
                                <p class="mb-0"><strong>2. Book</strong>: Which book of PNL under current broker will be
                                    shown in telegram</p>
                                <p class="mb-0"><strong>3. Telegram Channel</strong>: Telegram Channel ID</p>
                            </v-alert>
                            <v-tabs v-if="allowJsonEditor == 1">
                                <v-tab v-on:click="changeTab('table')">Table</v-tab>
                                <v-tab v-on:click="changeTab('json')">Json</v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                    <v-row class="pt-4 pl-4 pr-4 pb-4" v-if="selectedKey == 'table'">
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-textarea rows="1" label="Telegram Channel" auto-grow v-model="pnlConfig.telegram_channel"
                                class="grey--text text--lighten-1 text-body-2 mb-4">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-textarea rows="1" label="Book (Seprate by Comma)" auto-grow v-model="pnlConfig.book"
                                class="grey--text text--lighten-1 text-body-2 mb-4">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <v-textarea rows="1" label="Interval (Seconds)" auto-grow v-model="pnlConfig.interval"
                                class="grey--text text--lighten-1 text-body-2 mb-4">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-btn :loading="pnlLoading" color="primary" plain v-on:click="updatePnlTableConfig">
                                Update
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pt-4 pl-4 pr-4 pb-4" v-if="selectedKey == 'json'">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <JsonEditor ref="jsonEditor" v-model="dialog.config" />
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-btn color="primary" plain @click="updatePnlJsonConfig()" :loading="dialog.loading">
                                Update
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import * as apiNotifyPnl from "@components/api/pk/settings/notify-pnl";
import { mapState } from "vuex";
import JsonEditor from "@components/ComponentJsonEditor";

export default {
    props: {
        brokerId: null,
        broker: null
    },
    components: { JsonEditor },
    mixins: [permission],
    data () {
        return {
            pnlConfig: {},
            dialog: {
                name: "",
                config: {},
                loadJson: false,
                loading: false,
                broker: ""
            },
            pnlLoading: false,
            selectedKey: "table"
        };
    },
    computed: {
        ...mapState(["allowJsonEditor"])
    },
    methods: {
        changeTab (key) {
            this.selectedKey = key;
        },
        /**
         * 获取不同broker对应的pnl config
         */
        loadPnlConfig () {
            const self = this;
            const params = { broker: self.broker };

            self.dialog.loading = true;
            self.dialog.config = {};
            console.log(params);
            apiNotifyPnl.fetch(params).then((res) => {
                if (res.data.length != 0) {
                    self.pnlConfig = res.data;
                } else {
                    self.pnlConfig.telegram_channel = "";
                    self.pnlConfig.book = [];
                    self.pnlConfig.interval = "";
                }
                self.dialog.config = res.data;
                self.dialog.loadJson = true;
                self.dialog.loading = false;
            });
        },
        /**
         * 更新Pnl config-table
         */
        updatePnlTableConfig () {
            const self = this;
            this.pnlLoading = true;
            let newBook = self.pnlConfig.book;
            if (typeof newBook === "string") {
                newBook = newBook.split(",");
                self.pnlConfig.book = newBook;
            }
            if (self.pnlConfig.book == "") self.pnlConfig.book = [];
            self.pnlConfig.telegram_channel = Number(
                self.pnlConfig.telegram_channel
            );
            self.pnlConfig.interval = Number(self.pnlConfig.interval);
            const newPnlConfig = JSON.stringify(self.pnlConfig);
            const params = {
                broker: self.broker,
                broker_id: self.brokerId,
                config: JSON.parse(newPnlConfig)
            };
            console.log(params);
            apiNotifyPnl.update(params).then((res) => {
                setTimeout(() => {
                    self.$store.state.snacktimeout = 2500;
                    self.$store.state.snackbar = true;
                    self.$store.state.snackcolor = "success";
                    self.$store.state.snackmsg = " Config Update Successfully";
                }, 1450);
                setTimeout(() => {
                    self.pnlLoading = false;
                    this.loadPnlConfig();
                }, 2000);
            });
        }
    },
    mounted () {
        this.loadPnlConfig();
    }
};
</script>
